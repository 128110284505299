#header {
	#darkHeaderBar svg:hover {
		cursor: pointer;
		color: var(--darkGray);
	}

	#darkHeaderBar svg {
		padding-left: 10px;
		padding-right: 10px;
	}
}
