.geTitle,
.geHsplit,
.geFooterContainer,
.geMenubarContainer {
	display: none !important;
}

.geToolbarContainer.alignmentButtons {
	display: inline-block !important;
}

.geSidebarContainer {
	bottom: 1px !important;
	background: #e6e6e6;
	top: 2px !important;
	box-shadow: 4px 0 4px rgba(0, 0, 0, 0.3);
	z-index: 2;
	padding-top: 25px;
	width: 70px !important;
}

.geDiagramContainer {
	bottom: 0 !important;
	top: 0 !important;
	right: 0 !important;
	left: 0 !important;
}

.geSidebar {
	border-bottom: none;
	padding: 0;
}

.geItem {
	height: 100% !important;
	width: 98% !important;
}

.geItem:hover {
	background-color: #fafafa !important;
}

.thumbTitle {
	font-size: 11px !important;
	padding-top: 0 !important;
	white-space: normal !important;
}

.thumbSvg {
	width: 98% !important;
}

.thumbSvg > g > g:nth-child(2) {
	transform: translate(12px, 0px);
}

.editToggle {
	margin-left: 0 !important;
}

.editToggle > span {
	font-size: 90% !important;
}

.geFormatContainer {
	display: none;
	margin-top: 65px;
	padding-top: 0;
	right: 15px !important;
	width: 240px !important;
	box-shadow: none;
}

.max {
	border: 2px red solid;
	height: 20px !important;
	width: 20px !important;
}
