.sidebarModule {
	.links > a {
		margin-bottom: 5px;
		margin-top: 5px;
		font-family: "Raleway", sans-serif;
		font-weight: 700;
		display: block;
		color: var(--dark);
		text-decoration: none;
	}

	.links > a:hover {
		text-decoration: underline;
		cursor: pointer;
	}

	.links > a.activePage {
		color: var(--red);
	}

	.links > a.activePage:hover {
		cursor: default;
		text-decoration: none;
	}

	svg {
		margin-left: 5px;
		position: absolute;
		right: 10px;
	}
}
